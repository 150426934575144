import { apiCall } from '../../../../Middleware/API/apiRequest';
import { getTokensFromStorage } from '../../../../Utils/Auth/AuthToken';
import {
  AUTEST_RUN_SET_ARGUMENT,
  LOOKUP_BY_TYPE,
} from '../../../../constants/apiRoutes';
import {
  getGraphqlCacheResponse,
  setGraphqlCacheResponse,
} from '../../../../Utils/Storage/IndexDB/indexDbWrappers';
import { doubleEncode } from '../../../../Utils/Encode/UrlHelper';
/**
 * list Run Set Arguments.
 * @returns {Promise} - A promise that resolves with the list of Run Set Arguments or rejects with an error.
 * @param props
 */
export const listRunSetArgument = async ({
  accessToken,
  limit,
  offset,
  filter,
  sort,
  moduleName,
  isServiceWorker,
  listController,
  uc_ossi_run_id,
}) => {
  const data = {
    Filters: filter,
    pageNumber: offset === 0 ? 1 : offset,
    pageSize: limit,
    sortField: sort,
  };
  const response = await apiRequest({
    url: `${AUTEST_RUN_SET_ARGUMENT.replace('{runId}', doubleEncode(uc_ossi_run_id))}/Filter`,
    method: 'POST',
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  if (response?.isError) {
    if (isServiceWorker) {
      let cacheResponse = await getGraphqlCacheResponse(
        headers,
        isServiceWorker,
      );
      return cacheResponse;
    }
    return response;
  }
  if (isServiceWorker) {
    await setGraphqlCacheResponse(headers, response, isServiceWorker);
  }
  return {
    RunSetArguments: response.Payload,
    TotalCount: response?.TotalRecordCount,
  };
};

/**
 * Create Run Set Argument .
 * @returns {Promise} - A promise that resolves with the creation of Run Set Argument or rejects with an error.
 * @param data
 */
export const createRunSetArgument = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${AUTEST_RUN_SET_ARGUMENT.replace('{runId}', doubleEncode(data?.uc_ossi_run_id))}`,
    method: 'POST',
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return response;
};

/**
 * Update Run Set Argument.
 * @returns {Promise} - A promise that resolves with the update of Run Set Argument or rejects with an error.
 * @param data
 */
export const updateRunSetArgument = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${AUTEST_RUN_SET_ARGUMENT.replace('{runId}', doubleEncode(data?.uc_ossi_run_id))}`,
    method: 'PUT',
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return response;
};

/**
 * Update Run Set Argument.
 * @returns {Promise} - A promise that resolves with the update of Run Set Argument or rejects with an error.
 * @param data
 */
export const deleteRunSetArgument = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${AUTEST_RUN_SET_ARGUMENT.replace('{runId}', doubleEncode(data?.uc_ossi_run_id))}/${doubleEncode(data?.uc_ossi_af_arg_id)}?tenantid=${encodeURIComponent(data?.tenant_id)}&uc_ossi_prod_id=${encodeURIComponent(data?.uc_ossi_prod_id)}&uc_ossi_af_arg_id=${encodeURIComponent(data?.uc_ossi_af_arg_id)}`,
    method: 'DELETE',
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return response;
};

/**
 * Update Run Set Argument Order.
 * @returns {Promise} - A promise that resolves with the creation of Run Set or rejects with an error.
 * @param data
 */
export const updateRunSetArgumentOrder = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
  uc_ossi_run_id,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${AUTEST_RUN_SET_ARGUMENT.replace('{runId}', doubleEncode(uc_ossi_run_id))}/Order`,
    method: 'PUT',
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return response;
};

/**
 * Get Lookup By Type.
 * @returns {Promise} - A promise that resolves with the list of lookup or rejects with an error.
 * @param data
 */
export const getLookupByType = async ({
  moduleName,
  isServiceWorker,
  listController,
  type,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${LOOKUP_BY_TYPE}?type=${type}`,
    method: 'GET',
    moduleName,
    isServiceWorker,
    listController,
    accessToken,
  });
  return response;
};

/**
 * UserDetail Api Call Wrapper Method.
 * @param {object} api - Contains details like url headers method and data.
 * @returns {Promise} - A promise that resolves with the data of user or rejects with an error.
 */
const apiRequest = async (request) => {
  let response;
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  request.headers = headers;
  try {
    response = await apiCall(request);
  } catch (e) {
    response = { isSuccess: false, message: e.message };
  }
  return response;
};
