import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { RUNSETDETAILS } from '../../../../constants/applicationConstants';
import {
  DataTableHoc,
  Loader,
  ErrorFallback,
  buildNotification,
  GenerateNotification,
  deleteFilter,
  applyFilter,
  saveFilters,
  DropDownInput,
} from 'smart-react';
import { GridColumnsList } from '../Components/Filter/FilterElements';
import DataColumns from '../Components/DataColumns/DataColumns';
import {
  ROW_LAYOUTS,
  PAGE_SIZE,
} from '../../../../constants/applicationConstants';
import './RunSetDetails.scss';
const BaseRunSetDetailDataTable = React.lazy(
  () => import('../Components/DataTable/DataTable'),
);
import { Button } from '@progress/kendo-react-buttons';
import { listTestCase } from '../../TestCases/Services/TestCaseService';
import { createRunSetDetail } from '../Services/RunSetDetailService';
import {
  LookUp_Mode,
  Lookup_Selection_Mode,
  CONTAINS_OP,
} from '../../../../constants/applicationConstants';
import TestCaseLookupSlider from '../../TestCases/Components/Lookup/TestCaseLookupSlider';
import ValidatePermissions from '../../../../Utils/Auth/ValidatePermissions';
import {
  CREATE_MESSAGE,
  ERROR_MESSAGE,
} from '../../../../constants/notificationMessages';
import {
  EVENTS_DATA_TYPES,
  NOTIFICATION_TYPES,
} from '../../../../constants/eventDataTypes';
const RunSetDetailDataTable = DataTableHoc(BaseRunSetDetailDataTable);

/**
 * RunSetDetails main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The RunSetDetails component.
 */
const RunSetDetails = ({ runSet }) => {
  const [userData, setUserData] = React.useState([]);
  const dataColumnsArray = DataColumns({});
  const [testCaseData, setTestCaseData] = React.useState([]);
  const [currentTestCase, setCurrentTestCase] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  // used to check API is ready to call
  const [isReady, setIsReady] = React.useState(false);
  const [reloadData, setReloadData] = React.useState(false);

  const [testCaseSlideShow, setTestCaseSlideShow] = React.useState(false);
  const [selectedTCLookupItems, setSelectedTCLookupItems] = React.useState([]);

  const createSingleRunSetDetail = async ({ data }) => {
    let response = await createRunSetDetail({
      data: data,
      moduleName: 'runset',
      isServiceWorker: false,
      uc_ossi_run_id: runSet?.uc_ossi_run_id,
    });
    return response;
  };
  /**
   * handle dropdown Change
   * @e object
   */
  const addTestCaseItem = async () => {
    if (currentTestCase) {
      if (ValidatePermissions(currentTestCase)) {
        setLoading(true);
        setReloadData(false);
        let response = await createSingleRunSetDetail({
          data: {
            tenant_id: runSet?.tenant_id,
            uc_ossi_run_id: runSet?.uc_ossi_run_id,
            uc_ossi_run_seq: '',
            uc_ossi_run_ord: '',
            uc_ossi_app_test_id: currentTestCase.uc_ossi_app_test_id,
            uc_ossi_test_case_id: currentTestCase.uc_ossi_test_case_id,
            uc_env_id: '%',
            uc_ossi_disable_flg: 0,
            uc_ossi_sleep_sec: '',
            uc_ossi_user_cnt: '',
            uc_ossi_step_run_till_expr: '',
            uc_ossi_step_run_id: '',
            uc_ossi_descr: '',
          },
        });
        try {
          if (response.Message) {
            GenerateNotification(
              buildNotification({
                title: ERROR_MESSAGE?.title,
                description: response.Message,
                style: ERROR_MESSAGE?.style,
              }),
              NOTIFICATION_TYPES.APP,
              EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
            );
          } else if (!response?.IsSuccess) {
            GenerateNotification(
              buildNotification({
                title: ERROR_MESSAGE?.title,
                description: response.errorMessage,
                style: ERROR_MESSAGE?.style,
              }),
              NOTIFICATION_TYPES.APP,
              EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
            );
          } else {
            GenerateNotification(
              buildNotification(CREATE_MESSAGE),
              NOTIFICATION_TYPES.APP,
              EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
            );
            setReloadData(true);
            setCurrentTestCase(null);
          }
        } catch (error) {
          GenerateNotification(
            buildNotification(ERROR_MESSAGE),
            NOTIFICATION_TYPES.APP,
            EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
          );
        } finally {
          setLoading(false);
        }
      } else {
        GenerateNotification(
          buildNotification({
            title: 'Invalid Test Case!',
            description: 'An error occurred while selecting the test case',
            style: ERROR_MESSAGE?.style,
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );
        return;
      }
    } else {
      GenerateNotification(
        buildNotification({
          title: 'Please Select Test Case!',
          description: 'An error occurred while selecting the test case',
          style: ERROR_MESSAGE?.style,
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
      );
      return;
    }
  };

  /**
   * Used to show/hide test case lookup slide
   */
  const handleTestCaseSlide = () => {
    if (testCaseSlideShow) {
      setTestCaseSlideShow(false);
    } else {
      setTestCaseSlideShow(true);
    }
  };

  /**
   * handle dropdown Change
   * @e object
   */
  const handleDropdownChange = (e) => {
    setCurrentTestCase(e.value);
  };

  /**
   * filter Change
   * @event object
   */
  const filterChange = async (event) => {
    let filter = [
      {
        ColumnName: 'TestCaseId',
        Op: CONTAINS_OP,
        ColumnValue: event.filter.value,
      },
    ];
    const tests = await fetchTestCases(filter);
    setTestCaseData(tests);
  };

  /**
   * fetch Tests
   * @filter {Array}
   */
  const fetchTestCases = async (filter) => {
    setLoading(true);
    const response = await listTestCase({
      limit: '50',
      offset: 1,
      filter: filter ? filter : [],
      moduleName: 'TestCase',
      isServiceWorker: false,
    });
    setLoading(false);
    return response.tests;
  };

  React.useEffect(() => {
    (async () => {
      const tests = await fetchTestCases('');
      setTestCaseData(tests);
    })();
  }, []);
  /**
   * add Test Case Items
   * @items {Array}
   */
  const addTestCaseItems = async (items) => {
    setLoading(true);
    setReloadData(false);
    for (const item of items) {
      await createSingleRunSetDetail({
        data: {
          tenant_id: runSet?.tenant_id,
          uc_ossi_run_id: runSet?.uc_ossi_run_id,
          uc_ossi_run_seq: '',
          uc_ossi_run_ord: '',
          uc_ossi_app_test_id: item.uc_ossi_app_test_id,
          uc_ossi_test_case_id: item.uc_ossi_test_case_id,
          uc_env_id: '%',
          uc_ossi_disable_flg: 0,
          uc_ossi_sleep_sec: '',
          uc_ossi_user_cnt: '',
          uc_ossi_step_run_till_expr: '',
          uc_ossi_step_run_id: '',
          uc_ossi_descr: '',
        },
      });
    }
    setLoading(false);
    // Once all items are processed, trigger the notification and reload data
    GenerateNotification(
      buildNotification(CREATE_MESSAGE),
      NOTIFICATION_TYPES.APP,
      EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
    );
    setReloadData(true);
  };

  React.useEffect(() => {
    if (selectedTCLookupItems?.length > 0) {
      addTestCaseItems(selectedTCLookupItems);
    }
  }, [selectedTCLookupItems]);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className='container mx-auto k-px-4'>
        {loading && <Loader />}
        <div className='runset-detail'>
          <div>
            <div className='k-pt-6 k-pb-3 field-wrapper add-test-case-row'>
              <div className='k-align-items-center k-d-flex'>
                <span className='k-mr-2 test-case-dropdown-label'>
                  Select Test Case:
                </span>
              </div>
              <div className='testcase-dropdown'>
                <DropDownInput
                  textField={'TestCaseId'}
                  dataItemKey={'uc_ossi_test_case_id'}
                  className=' k-align-self-end'
                  dataSet={testCaseData}
                  value={currentTestCase}
                  defaultItem={{
                    TestCaseId: 'Select Test Case...',
                    disabled: true,
                  }}
                  skipDisabledItems={false}
                  handleChange={handleDropdownChange}
                  filterable={true}
                  onFilterChange={filterChange}
                />
              </div>

              <div className='k-d-flex'>
                <Button
                  themeColor={'primary'}
                  className='k-mx-1'
                  icon='search'
                  onClick={() => setTestCaseSlideShow(true)}
                ></Button>
                <Button
                  themeColor={'primary'}
                  className='k-mx-1'
                  disabled={!currentTestCase}
                  onClick={() => addTestCaseItem()}
                >
                  Add
                </Button>
              </div>
            </div>
          </div>
        </div>
        <React.Suspense fallback={<Loader />}>
          <RunSetDetailDataTable
            runSet={runSet}
            deleteFilter={deleteFilter}
            applyFilter={applyFilter}
            saveFilters={saveFilters}
            moduleName={RUNSETDETAILS}
            dataColumns={dataColumnsArray}
            gridColumnsList={GridColumnsList}
            dataTableName={RUNSETDETAILS}
            setIsReady={setIsReady}
            isReady={isReady}
            rowLayoutConfigName={`${RUNSETDETAILS}.DataGrid.RowLayout`}
            pageLengthConfigName={`${RUNSETDETAILS}.DataGrid.ItemsPerPage`}
            dataSet={userData}
            setDataSet={setUserData}
            initialSort={{
              field: 'id',
              dir: 'desc',
            }}
            isCardonMobileView={false}
            total={process.env.DefaultLimit}
            defaultRowLayout={process.env.RowLayout}
            rowLayouts={ROW_LAYOUTS}
            pageSizes={PAGE_SIZE}
            reloadData={reloadData}
            isGridSortable={{ sortable: true, sendPartialData: false }}
          />
        </React.Suspense>
      </div>
      <TestCaseLookupSlider
        show={testCaseSlideShow}
        handleSlide={handleTestCaseSlide}
        setSelectedLookupItems={setSelectedTCLookupItems}
        selectedLookupItems={selectedTCLookupItems}
        lookupSelectionMode={Lookup_Selection_Mode.multiple}
        mode={LookUp_Mode.LookUp}
      />
    </ErrorBoundary>
  );
};
export default RunSetDetails;
